import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {graphql} from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Agecolumns from "../../blocks/agecolumns";

export const data = graphql`query DelweddQuery {
  banner: file(relativePath: {eq: "SVG/home-content-banner-cy.svg"}) {
    publicURL
  }
  bird: file(relativePath: {eq: "SVG/home-bird.svg"}) {
    publicURL
  }
  teachersguide: file(relativePath: {eq: "cy/guide/Canllaw_i_Athrawon.pdf"}) {
    publicURL
  }
}`

const ageGroup = [
    {
        image: 'SVG/home-5-7.svg',
        ageGroup: '5-7',
        resourcesLink: '/cy/5-7/handbook/',
        resourcesLinkText: 'Dechrau',
        glossaryLink: '/cy/vocabulary/',
        glossaryLinkText: 'Geirfa',
    },
    {
        image: 'SVG/home-7-9.svg',
        ageGroup: '7-9',
        resourcesLink: '/cy/7-9/handbook/',
        resourcesLinkText: 'Dechrau',
        glossaryLink: '/cy/vocabulary/',
        glossaryLinkText: 'Geirfa',
    },
    {
        image: 'SVG/home-9-11.svg',
        ageGroup: '9-11',
        resourcesLink: '/cy/9-11/handbook/',
        resourcesLinkText: 'Dechrau',
        glossaryLink: '/cy/vocabulary/',
        glossaryLinkText: 'Geirfa',
    }
]

const IndexPage = ({ data }) => {
    return (
        <Layout lang={'en'} langURL={'/en/'}>
            <Seo title="Croeso Athrawon!" description="All the information you need to teach your class"
                 lang={'cy'}/>
            <section className={'home-banner'} role={'banner'}>
                <Container fluid={true} bsPrefix={'wrap'} className={'h-100'}>
                    <Row className={'h-100'}>
                        <div className={'home-banner--content offset-7 d-flex h-100'}>
                            <p className={'home-banner--content__text my-auto'}>
                                Syniadau ar gyfer gweithgaredd dysgu, adnoddau cymorth ac offer defnyddiol i athrawon!
                            </p>
                        </div>
                    </Row>
                </Container>
            </section>
            <main id={'content'} className={'content py-5'} role={'main'}>
                <Container bsPrefix={'wrap'} fluid={false}>
                    <Row>
                        <Col xs={12} className={'pb-5'}>
                            <h1 className={'content--h1'}>Mae eich Hwb Athrawon wedi’i greu gyda chi mewn golwg!</h1>
                            <p>Yng Nghymdeithas Adeiladu Principality rydym yn cydnabod nad yw addysgu addysg ariannol yn hawdd a gall fod yn anodd dod o hyd i ddeunyddiau cefnogi defnyddiol, felly roeddem ni eisiau helpu.</p>
                            <p>Mae’r wefan hon yn cynnig adnoddau, cynlluniau gwersi a chymorth cwricwlwm i’ch helpu i gyflwyno addysg ariannol mewn modd hwyl a diddorol a helpu i hyrwyddo agweddau cyfrifol tuag at faterion ariannol.</p>
                            <p>Mae rhaglenni addysg ariannol llawn, yn ogystal â gweithgareddau ac adnoddau unigol, i gynorthwyo eich addysg ariannol bresennol. Lawrlwythwch y Canllaw i athrawon i gael mwy o wybodaeth.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={'content--banner pb-5'}>
                            <a href={data.teachersguide.publicURL} target={'_blank'} rel={'noreferrer'}>
                                <img className={'w-100'} alt={'Cliciwch yma ar gyfer y Canllaw i athrawon'} loading={'lazy'}
                                     src={data.banner.publicURL}/>
                            </a>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={'content--home--column--bird'}>
                            <h2>Pa grŵp oedran ydych chi’n ei ddysgu?</h2>
                            <p>Gallwch gael gafael ar syniadau gweithgareddau dysgu diddorol, adnoddau cefnogi ac offer defnyddiol i athrawon. Gallwch ddewis lawrlwytho deunyddiau i bob grŵp oedran yn sesiynau unigol neu gallwch ddefnyddio’r Canllaw i athrawon i roi cynnig ar ein taith gysylltiedig i ddysgwyr trwy wahanol bynciau ac amcanion dysgu.</p>
                            <p>Edrychwch ar y gweithgareddau a’r adnoddau sydd ar gael ar gyfer eich ystod oedran neu lawrlwythwch y rhestr eirfa.</p>
                            <img alt={'Little bird'} loading={'lazy'} src={data.bird.publicURL}
                                 style={{width: '60px'}}/>
                        </Col>
                        {ageGroup.map((val, idx) => (
                            <Agecolumns key={idx} image={val.image} ageGroup={val.ageGroup}
                                        resourcesLink={val.resourcesLink} resourcesLinkText={val.resourcesLinkText}
                                        glossaryLink={val.glossaryLink} glossaryLinkText={val.glossaryLinkText}/>
                        ))}
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <p>Lawrlwythwch gynlluniau gwersi, taflenni ac adnoddau ar gyfer eich dosbarth i helpu i gefnogi taith addysg ariannol eich disgyblion, neu edrychwch ar y rhestr eirfa a drafodir yn y grŵp oedran hwn.</p>
                        </Col>
                    </Row>
                </Container>
            </main>
        </Layout>
    )
}

export default IndexPage